import React from "react";
import { GiCompass, GiDiamondHard, GiStabbedNote } from "react-icons/gi";
import { FiHome } from "react-icons/fi";
export const links = [
  {
    id: 1,
    text: "головна",
    url: "/",
  },
  {
    id: 2,
    text: "про нас",
    url: "/about",
  },
  {
    id: 3,
    text: "товари",
    url: "/products",
  },
];

export const services = [
  {
    id: 1,
    icon: <FiHome />,
    title: "функціональність",
    text: "Наші будівельні вагончики створені з особливим акцентом на максимальну ефективність простору та функціональність. Незалежно від ваших потреб - будівництво, відпочинок або зберігання обладнання - ми пропонуємо оптимальні рішення, які максимально враховують ваші потреби та вимоги.",
  },
  {
    id: 2,
    icon: <GiDiamondHard />,
    title: "Якість",
    text: "Кожен етап виготовлення наших будівельних вагончиків контролюється та тестується, щоб гарантувати їх надійність та довговічність. Ми використовуємо тільки найкращі матеріали, щоб кожен наш товар відповідав найвищим стандартам якості та задовольняв ваши потреби.",
  },
  {
    id: 3,
    icon: <GiStabbedNote />,
    title: "Історія",
    text: "З 2017 ми працюємо в галузі будівельних конструкцій, надаючи нашим клієнтам надійні та функціональні рішення. Наша історія - це історія успіху, яка об'єднує традиції та інновації для створення найкращих виробів для наших клієнтів.",
  },
];

export const products_url = "http://localhost:3001/api/products/";

export const single_product_url = `http://localhost:3001/api/products/`;
